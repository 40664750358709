import React, { useEffect, useState } from 'react';
import minusIcon from '../../assets/icons/ic_minus.svg'
import plusIcon from '../../assets/icons/ic_plus.svg'

const CounterPopup = ({ isOpen, children, onClose, onSend, currentNum, setCurrentNum ,type, name }) => {
    const [show, setShow] = useState(isOpen);

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
    }

    const upgradeNum = () => {
        if(name === 'timeout') {
            if (currentNum !== 180) {
                setCurrentNum(currentNum + 15);
            }
        }
        if(name === 'battery'){
            if (currentNum !== 50) {
                setCurrentNum(currentNum + 10);
            }
        } else if (name === 'charged'){
            if (currentNum !== 100) {
                setCurrentNum(currentNum + 10);
            }
        }
    }

    const downgradeNum = () => {
        if(name === 'timeout') {
            if (currentNum !== 15) {
                setCurrentNum(currentNum - 15);
            }
        }
        if(name === 'battery'){
            if (currentNum !== 10) {
                setCurrentNum(currentNum - 10);
            }
        } else if (name === 'charged'){
            if (currentNum !== 10) {
                setCurrentNum(currentNum - 10);
            }
        }
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-[full] transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-10 border w-[500px] shadow-lg rounded-md bg-white ml flex flex-col items-center"
                onClick={e => e.stopPropagation()}
            >
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
                <div className='flex flex-row  mt-8 w-fit'>
                    <div className='p-5 bg-slate-200 rounded-md flex items-center hover:bg-opacity-50 cursor-pointer' onClick={downgradeNum}>
                        <img src={minusIcon} className='h-[3px]' alt='minus' />
                    </div>
                    <div className='flex flex-col items-center justify-center'>
                        <span className='px-8 font-heebo text-2xl'>{currentNum}</span>
                        <span className='px-8 font-heebo text-xl font-light -mt-1'>{type}</span>
                    </div>
                    <div className='p-5 bg-slate-200 rounded-md flex items-center hover:bg-opacity-50 cursor-pointer' onClick={upgradeNum}>
                        <img src={plusIcon} className='h-5' alt='plus' />
                    </div>
                </div>

                <div className='w-full flex justify-center mt-10'>
                    <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleClose}>
                        <span className='text-white font-heebo'>Cancel</span>
                    </div>
                    <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer ml-5' onClick={handleSend}>
                        <span className='text-white font-heebo'>Apply</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterPopup;
