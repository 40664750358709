import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header';
import plusIcon from '../../assets/icons/ic_plus_blue.svg';
import statusIcon from '../../assets/icons/ic_statuspatient.svg';
import statusIconGrey from '../../assets/icons/ic_status_offline.svg';
import downIcon from '../../assets/icons/ic_arrowdown.svg';
import upIcon from '../../assets/icons/ic_arrowup.svg';
import menuIcon from '../../assets/icons/ic_menu.svg';

import IconTint from 'react-icon-tint';
import { addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where, Timestamp, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useAtom } from 'jotai';
import { currentRobot, isAddUserOpen } from '../store/DataStore';
import { formatDistanceToNow } from 'date-fns';
import NewUserPopup from '../popups/NewUserPopup';
import EditUserPopup from '../popups/EditUserPopup';
import { createUserWithEmailAndPassword, getAuth, updateProfile, sendPasswordResetEmail } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';
import RemoveUserPopup from '../popups/RemoveUserPopup';
import RobotSerialNumberPopup from '../popups/RobotSerialNumberPopup'; // Import the new popup component



const UsersFragment = ({userName}) => {
  const [matchedUsers, setMatchedUsers] = useState([]);
  const [savedRobot] = useAtom(currentRobot);
  const [addUserStatus, setUserStatus] = useAtom(isAddUserOpen);
  const [error, setError] = useState(null);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [robotPopupStatus, setRobotPopupStatus] = useState(false); // State for RobotSerialNumberPopup



  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
        const querySnapshot = await getDocs(usersCollectionRef);
        const usersData = [];

        querySnapshot.forEach((doc) => {
          const userData = doc.data();
          console.log('Doc data is ' + userData.robots);
          console.log('Current robot is ' + savedRobot);
          if (userData.robots && userData.robots.some(robot => robot === savedRobot)) {
            usersData.push(userData);
          }
        });
        setMatchedUsers(usersData);
        console.log("List of users is: ", usersData);
      } catch (error) {
        console.error('Error fetching users: ', error);
      }
    };
    fetchUsers();
  }, [savedRobot]);

  async function fetchUsers() {
    try {
      const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
      const q = query(usersCollectionRef);
      const querySnapshot = await getDocs(q);
      const usersData = [];

      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        console.log('Doc data is ' + userData.robots);
        console.log('Current robot is ' + savedRobot);
        if (userData.robots && userData.robots.some(robot => robot === savedRobot)) {
          usersData.push(userData);
        }
      });

      setMatchedUsers(usersData);
    } catch (error) {
      console.error("Error fetching users: ", error);
    }
  }

  const addNewUser = async (fullname, email, phone, password, role) => {
    const auth = getAuth();
    console.log('error' + error);
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        return updateProfile(userCredential.user, {
          displayName: fullname,
        });
      })
      .then(() => {
        console.log('Successfully created new user with display name:', fullname);
      })
      .catch((error) => {
        console.error('Error:', error);
        if (setError) setError(error.message);
      });

    try {
      const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
      const docRef = await addDoc(usersCollectionRef, {
        email: email,
        id: uuidv4(),
        isActive: false,
        lastlogin: Timestamp.fromDate(new Date()),
        name: fullname,
        phone: phone,
        robots: [savedRobot],
        role: 'User'
      });
      console.log("User added with ID: ", docRef.id);
      await fetchUsers(); // Fetch users after adding new user
    } catch (error) {
      console.error("Error adding user: ", error);
    }
  };

  const handleEdit = (userData) => {
    setEditData(userData);
    setIsEditPopupOpen(true);
  };

  const lastSeenConverter = (lastSeenTimestamp) => {
    if (!lastSeenTimestamp || typeof lastSeenTimestamp.toDate !== 'function') {
      console.error('Invalid timestamp: lastSeenTimestamp should be a Firestore Timestamp object.');
      return 'Loading...';
    }
    const lastSeenDate = lastSeenTimestamp.toDate();
    return formatDistanceToNow(lastSeenDate, { addSuffix: true });
  };

  async function createRobotData(robotSerialNumber) {
    try {
      // Define the paths and data
      const data = {
        Actions: {
          Ping: {
            pingsetting: {
              is_online: true
            }
          }
        },
        Settings: {
          Actions: {
            actionssettings: {
              area_selected: 'home base',
              reqattempts_times: 2,
              reqduration: '180',
              reqduration_sec: 5,
              reqfetchattempts_times: 2,
              reqfetchduration_sec: 5
            }
          },
          Advanced: {
            advancedsetting: {
              toggle_reqperms: true,
              toggle_topbar: false
            },
            selectedmap: {
              imageUrl: ''
            }
          },
          Battery: {
            batterysetting: {
              chargedlevel_percent: 80,
              chargelevel_percent: 30,
              toggle_charged: true,
              warninglevel_percent: 20
            }
          },
          Controller: {
            actions: {
              announce_message: '',
              current_location: '',
              currvideo_index: 0,
              display_message: '',
              escort_name: '',
              escortfrom_location: '',
              escortto_location: '',
              fetch_from: '',
              fetch_item: '',
              fetch_waittime: '',
              follow_me: false,
              is_current: false,
              is_escort: false,
              is_escorted: false,
              is_fetch: false,
              is_fetched: false,
              is_goto: false,
              is_qrshow: false,
              is_refresh: false,
              qrcode_title: '',
              qrcode_url: '',
              selected_location: '',
              toggle_announce: false,
              toggle_clear: false,
              toggle_display: false,
              toggle_message: false,
              toggle_restart: false,
              toggle_startvid: false
            },
            settings: {
              app_version: '1.0.1',
              toggle_backuploc: false,
              toggle_displayface: false,
              toggle_hardwaredisable: false,
              toggle_kioskaction: false,
              toggle_kioskmode: false,
              toggle_letschatmode: false,
              toggle_obsnotif: false,
              toggle_restart: false,
              toggle_restoreloc: false,
              toggle_updatemap: false,
              toggle_voiceackno: false,
              toggle_voicecommand: false,
              volume_amount: 2
            },
            videolibrary: {
              currvideo_index: 0,
              toggle_startvid: false
            }
          },
          Hardware: {
            hardwaresetting: {
              isGotoActive: false,
              is_kidmode: false
            }
          },
          Integration: {
            integrationsetting: {
              bdaycheck_msg: '',
              earlycheck_msg: '',
              headercheck_msg: '',
              latecheck_msg: '',
              successcheck_msg: ''
            }
          },
          Kiosk: {
            kiosksetting: {
              location: '',
              timeout: 180,
              toggle_timeout: false
            }
          },
          Password: {
            Kiosk: {
              exit_password: '0616'
            },
            Video: {
              password: '0616',
              toggle_lock: false
            }
          },
          bot: {
            openai: {
              api_key: ''
            },
            prompt: {
              content: '',
              knowledge: '',
              timeout: '8'
            }
          }
        }
      };
  
      // Path: Companies/Zeeno_Dev/Actions/{robotSerialNumber}/Ping/pingsetting
      await setDoc(doc(db, `Companies/Zeeno_Dev/Actions/${robotSerialNumber}/Ping/pingsetting`), data.Actions.Ping.pingsetting);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Actions/actionssettings
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Actions/actionssettings`), data.Settings.Actions.actionssettings);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Advanced/advancedsetting
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Advanced/advancedsetting`), data.Settings.Advanced.advancedsetting);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Advanced/selectedmap
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Advanced/selectedmap`), data.Settings.Advanced.selectedmap);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Battery/batterysetting
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Battery/batterysetting`), data.Settings.Battery.batterysetting);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Controller/actions
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Controller/actions`), data.Settings.Controller.actions);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Controller/settings
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Controller/settings`), data.Settings.Controller.settings);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Controller/videolibrary
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Controller/videolibrary`), data.Settings.Controller.videolibrary);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Hardware/hardwaresetting
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Hardware/hardwaresetting`), data.Settings.Hardware.hardwaresetting);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Integration/integrationsetting
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Integration/integrationsetting`), data.Settings.Integration.integrationsetting);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Kiosk/kiosksetting
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Kiosk/kiosksetting`), data.Settings.Kiosk.kiosksetting);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Password/Kiosk
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Password/Kiosk`), data.Settings.Password.Kiosk);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/Password/Video
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/Password/Video`), data.Settings.Password.Video);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/bot/openai
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/bot/openai`), data.Settings.bot.openai);
  
      // Path: Companies/Zeeno_Dev/Settings/{robotSerialNumber}/bot/prompt
      await setDoc(doc(db, `Companies/Zeeno_Dev/Settings/${robotSerialNumber}/bot/prompt`), data.Settings.bot.prompt);
  
      console.log('Documents successfully written!');
    } catch (error) {
      console.error('Error writing documents: ', error);
    }
  }
  

  const handleSendRobotSerialNumber = (serialNumber) => {
    // Handle sending robot serial number
    console.log('Robot Serial Number:', serialNumber);
    createRobotData(serialNumber);
    setRobotPopupStatus(false);
  };

  return (
    <div className='bg-[#F0F2FF] h-full px-10 py-6'>
      <Header />
      <NewUserPopup isOpen={addUserStatus} onClose={() => setUserStatus(false)} onSend={addNewUser}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Add New User</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">{`After sending invitation user will receive registration link via email.`}</p>
      </NewUserPopup>
      <div className='flex flex-row w-fit 2xl:mt-14 lg:mt-8 items-center'>
        <h1 className='font-heebo text-xl ml-3 mt-0.5 text-[#273a84]'>Users</h1>
        <img src={plusIcon} alt="" className="h-6 w-6 object-contain rounded-md ml-2 cursor-pointer" onClick={() => setUserStatus(true)} />
        {userName === 'Zeeno Admin' && (
          <button
            className="ml-4 bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={() => setRobotPopupStatus(true)}
          >
            Add Robot Serial Number
          </button>
        )}
      </div>
      <div className='mt-5'>
        {matchedUsers.map((item, index) => (
          <div key={index}>
            <UserModel name={item.name} email={item.email} phone={item.phone} lastSeen={lastSeenConverter(item.lastlogin)} isActive={item.isActive ? "Active" : "Not Active"} role={item.role} user={item} fetchUsers={fetchUsers} onEdit={handleEdit} />
          </div>
        ))}
      </div>
      {isEditPopupOpen && editData && (
        <EditUserPopup
          isOpen={isEditPopupOpen}
          user={editData}
          onClose={() => setIsEditPopupOpen(false)}
          onUpdate={(updatedData) => {
            // Handle the updated data
            console.log('Updated data:', updatedData);
            setIsEditPopupOpen(false);
            fetchUsers(); // Optionally, refetch users if necessary
          }}
        />
      )}
      <RobotSerialNumberPopup
        isOpen={robotPopupStatus}
        onClose={() => setRobotPopupStatus(false)}
        onSend={handleSendRobotSerialNumber}
      />
    </div>
  );
};

const UserModel = ({ name, email, phone, lastSeen, isActive, role, user, fetchUsers, onEdit }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  // const deleteUser = async (userValue) => {
  //   const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
  //   const userId = userValue.id;
  //   const userQuery = query(usersCollectionRef, where('id', '==', userId));
  //   let userIDToremove = '';

  //   const querySnapshot = await getDocs(userQuery);
  //   querySnapshot.forEach((doc) => {
  //     userIDToremove = doc.id;
  //   });

  //   try {
  //     const userDocRef = doc(db, `Companies/Zeeno_Dev/Users/${userIDToremove}`);
  //     await deleteDoc(userDocRef);
  //     console.log(`User with ID ${userIDToremove} deleted successfully.`);
  //     await fetchUsers(); // Fetch users after deletion
  //   } catch (error) {
  //     console.error('Error deleting user: ', error);
  //   }
  // };
  const deleteUser = async (userValue) => {
    setUserToDelete(userValue);
    setIsPopupOpen(true);
};

const handleConfirmDelete = async () => {
  const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
  const userId = userToDelete.id;
  const userQuery = query(usersCollectionRef, where('id', '==', userId));
  let userIDToremove = '';

  const querySnapshot = await getDocs(userQuery);
  querySnapshot.forEach((doc) => {
      userIDToremove = doc.id;
  });

  try {
      const userDocRef = doc(db, `Companies/Zeeno_Dev/Users/${userIDToremove}`);
      await deleteDoc(userDocRef);
      console.log(`User with ID ${userIDToremove} deleted successfully.`);
      await fetchUsers(); // Fetch users after deletion
  } catch (error) {
      console.error('Error deleting user: ', error);
  } finally {
      setIsPopupOpen(false);
      setUserToDelete(null);
  }
};

const handleCancelDelete = () => {
  setIsPopupOpen(false);
  setUserToDelete(null);
};

  const resetPassword = async (userValue) => {
    console.log('Resetting password for user:', userValue);
    const email = userValue.email;

    if (!email) {
      console.log('Invalid email address');
    }
  
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
    
    } catch (error) {
      console.error('Error sending password reset email:', error);
    }
  }
  

  return (
    <div className='flex flex-row mb-2.5'>
      <div className='flex flex-row h-14 bg-[#FFFF] px-2 bg-opacity-10 rounded-l-[100px] rounded-r-3xl items-center border-l-[#8290F8] border-l-[5px]'>
        {isActive === "Active" ?
          <img alt='patient_status' src={statusIcon} className='h-10' />
          :
          <img alt='patient_status_grey' src={statusIconGrey} className='h-10' />
        }
        <div className='flex flex-col w-32 ml-2'>
          <span className='font-heebo text-md'>{name}</span>
          <span className='font-heebo font-light text-[13px]'>{"(" + isActive + ")"}</span>
        </div>
        <div className='flex flex-col w-36'>
          <span className='font-heebo text-md'>{email}</span>
          <span className='font-heebo font-light text-sm'>Email</span>
        </div>
        <div className='flex flex-col w-40 ml-20'>
          <span className='font-heebo text-md'>{phone}</span>
          <span className='font-heebo font-light text-sm'>Phone</span>
        </div>
        <div className='flex flex-col w-48 ml-20'>
          <span className='font-heebo text-md'>{lastSeen}</span>
          <span className='font-heebo font-light text-sm'>Last Seen</span>
        </div>
        <div className='flex flex-col w-32 ml-20'>
          <TypeDropdown role={role} email={email} />
        </div>
      </div>
      <img src={menuIcon} alt='menu' className='ml-4 cursor-pointer' onClick={async () => {
        setShowMenu(!showMenu);
      }} />
      {showMenu && (
        <div ref={menuRef} className='absolute mt-7 ml-[825px] w-48 bg-[#FFFF] rounded-xl shadow-xl z-20 p-2'>
          <button className='block px-4 py-3 font-heebo font-light text-lg text-gray-700 hover:bg-blue-50 w-full text-left rounded-xl' onClick={() => onEdit(user)}>Edit</button>
          <button className='block px-4 py-4 font-heebo font-light text-lg text-gray-700 hover:bg-blue-50 w-full text-left rounded-xl' onClick={() => resetPassword(user)}>Reset Password</button>
          <button className='block px-4 py-2 font-heebo font-light text-lg text-red-500 bg-red-50 hover:bg-[#e7eaff] w-full text-left rounded-xl mt-2' onClick={() => deleteUser(user)}>Delete User</button>
        </div>
      )}
                  <RemoveUserPopup
                isOpen={isPopupOpen}
                userName={userToDelete?.name}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
    </div>
  );
};

const TypeDropdown = ({ role, email }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(role);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const myComponentRef = useRef(null);
  const list = ['User', 'Admin'];

  document.addEventListener("mousedown", (e) => {
    if (myComponentRef.current && !myComponentRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  });

  const updateUserRole = async (newRole) => {
    try {
      const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
      const q = query(usersCollectionRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log('No user found with the given email');
        return;
      }

      // Assuming email is unique and only one document will be returned
      const userDoc = querySnapshot.docs[0];
      const userDocRef = userDoc.ref;

      await updateDoc(userDocRef, {
        role: newRole,
      });

      console.log('User role updated successfully');
    } catch (error) {
      console.error('Error updating user role: ', error);
    }
  };

  return (
    <div ref={myComponentRef} className="relative w-fit transition-all delay-200 select-none">
      <div onClick={toggleDropdown} className={`cursor-pointer w-fit min-w-[110px] pl-1 pr-2 py-1 bg-[#3C5BD2] bg-opacity-10  ${isOpen ? 'rounded-bl-none rounded-b-none rounded-xl' : 'rounded-xl  hover:bg-opacity-5'}  focus:outline-none flex flex-row items-center justify-between`}>
        <span className='truncate text-[#3C5BD2] font-heebo ml-2 font-normal w-full text-ellipsis'>{selected}</span>
        {!isOpen ? <IconTint src={downIcon} className='h-3' color='#3C5BD2' /> : <IconTint src={upIcon} className='h-3 -mt-1' color='#3C5BD2' />}
      </div>

      {isOpen && (
        <div className="absolute left-92 z-10 w-full py-2 bg-[#e1e6fd] rounded-b-md shadow-xl ">
          {list.map((item, index) => (
            <span
              key={index}
              className="block px-4 py-2 text-sm text-[#3C5BD2] cursor-pointer font-heebo hover:bg-[#b9c7ff44]"
              onClick={() => {
                if (item.name == null) {
                  setSelected(item);
                  updateUserRole(item);
                  setIsOpen(false);
                } else {
                  setSelected(item);
                  updateUserRole(item);
                  setIsOpen(false);
                }
              }}
            >{`${item}`}</span>
          ))}
        </div>
      )}
    </div>
  );
};

export default UsersFragment;