import React, { useEffect, useState } from 'react'
import Header from '../Header'
import { currentRobot } from '../store/DataStore';
import { useAtom } from 'jotai';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';

const MapFragment = () => {
  const [savedRobot] = useAtom(currentRobot);
  const [mapImageUrl, setMapImageUrl] = useState("");

  const getMapImage = async (db, savedRobot) => {
    if (savedRobot !== '') {
      console.log("Robot saved: ", savedRobot);
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Advanced/selectedmap`;
      const docRef = doc(db, docPath);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const mapImage = docSnap.data().imageUrl;
          setMapImageUrl(mapImage)
          console.log("Document data:", docSnap.data());
          return mapImage;
        } else {
          console.log("No such document!");
          return [];
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        return [];
      }
    } else {
      console.log("No robot saved");
      return [];
    }
  }

  useEffect(() => {
    getMapImage(db, savedRobot)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRobot])
  
  return (
    <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto'>
      <Header />
      <h1 className='font-heebo text-2xl mt-14'>Map</h1>
      <h2 className='font-heebo font-light text-lg'>View the map of the robot's environment.</h2>
      <div className='w-[70%] h-[70%] bg-white mt-3'>
          <img src={mapImageUrl} alt='map' className='h-full w-full' key={mapImageUrl}/>
      </div>
    </div>
  )
}

export default MapFragment