import React, { useEffect, useState } from 'react'
import Header from '../Header'
import TaskModel from '../models/TaskModel'
import { useAtom } from 'jotai'
import { currentRobot } from '../store/DataStore'
import { useTasks } from '../helpers/useTasks'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../../firebase-config'

const TasksFragment = () => {
  const [savedRobot] = useAtom(currentRobot);
  const taskList = useTasks(savedRobot);
  const [currNetworkStatus, setCurrNetworkStatus] = useState('')

  const getTaskType = (taskContent) => {
    switch (taskContent) {
      case 'goto': return 'Go To';
      case 'escort': return 'Escort';
      case 'fetch': return 'Fetch';
      default: return taskContent;
    }
  };

  const getTaskStatus = (taskContent) => {
    switch (taskContent) {
      case 'paused': return 'At Hold';
      case 'active': return 'In Progress';
      case 'upcoming': return 'Upcoming';
      default: return taskContent;
    }
  };

  async function getCurrentPing(db) {
    if (!savedRobot || savedRobot.trim() === '') {
      console.log("No robot saved");
      return;
    }

    const docPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Ping/pingsetting`;
    const docRef = doc(db, docPath);

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const isOnline = data.is_online;
        console.log("Current online status: ", isOnline);
        if (isOnline) {
          setCurrNetworkStatus('Online');
        } else {
          setCurrNetworkStatus('Offline');
        }
      } else {
        console.log("Document does not exist!");
      }
    }, (error) => {
      console.error("Error listening to document: ", error);
    })
  }

  useEffect(() => {
    if (savedRobot) {
      getCurrentPing(db);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [db, savedRobot]);

  return (
    <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto'>
      <Header />
      <div className='flex flex-row w-fit 2xl:mt-14 lg:mt-8 items-center'>
        <h1 className='font-heebo text-xl  text-[#273a84]'>Tasks</h1>
      </div>
      {taskList && taskList.map((item, index) => (
        <TaskModel
          key={index}
          robotName={item.robot_name}
          networkStatus={currNetworkStatus}
          batteryAmount={item.battery_amount}
          wifiStatus={item.wifi_status}
          nextTasks={item.next_tasks}
          task={getTaskType(item.content[0])}
          duration={item.status === 'complete' ? 100 : 50}
          status={getTaskStatus(item.status)}
          taskId={item.id}
        />
      ))
      }
    </div>
  )
}

export default TasksFragment