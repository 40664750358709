import React, { useEffect, useState } from 'react';
import locpinIcon from '../../assets/icons/ic_locpin.svg';
import targetIcon from '../../assets/icons/ic_target.svg';
import IconTint from 'react-icon-tint';
import { useAtom } from 'jotai';
import { savedSelectedLoc } from '../store/DataStore';

const GotoPopup = ({ isOpen, children, onClose, onSend, locations }) => {
    const [show, setShow] = useState(isOpen);
    const [selectedLocation, setSelectedLocation] = useAtom(savedSelectedLoc);

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
                <div className="grid grid-cols-3 gap-4 mt-6">
                    {locations.map((item, i) => (
                        <div key={i} className={`flex items-center p-1 w-[150px] border-l-2 border-l-[#8290F8] ${selectedLocation === item ? 'bg-[#3C5BD2]' : 'bg-[#DCE0FF]'} rounded-l-3xl rounded-r-md cursor-pointer`} onClick={() => setSelectedLocation(item)}>
                            {selectedLocation === item ?
                                <IconTint src={locpinIcon} className='h-5' color='#FFFFFF' /> :
                                <IconTint src={locpinIcon} className='h-5' color='#152FF5' />
                            }
                            <div className={`ml-2 font-heebo font-light ${selectedLocation === item ? 'text-white' : 'text-black'}`}>{item}</div>
                        </div>
                    ))}
                </div>
                <div className='w-full flex justify-center mt-8'>
                    {selectedLocation !== '' ? 
                    <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                        <span className='text-white font-heebo'>Send Zeeno</span>
                        <IconTint src={targetIcon} className='h-5 ml-2' color='#FFFFFF' />
                    </div>
                    :
                    <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                        <span className='text-white font-heebo'>Send Zeeno</span>
                        <IconTint src={targetIcon} className='h-5 ml-2' color='#FFFFFF' />
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default GotoPopup;
