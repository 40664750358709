import React, { useEffect, useState } from 'react'
import Header from '../Header'
import speakerIcon from '../../assets/icons/ic_speakersetting.svg'
import muteIcon from '../../assets/icons/ic_muted.svg'
import hardwareIcon from '../../assets/icons/ic_hardwaresetting.svg'
import integrationIcon from '../../assets/icons/ic_integration.svg'
import kioskIcon from '../../assets/icons/ic_kiosksetting.svg'
import actionsIcon from '../../assets/icons/ic_actionsetting.svg'
import batteryIcon from '../../assets/icons/ic_batterysetting.svg'
import langIcon from '../../assets/icons/ic_langsetting.svg'
import settingIcon from '../../assets/icons/ic_settingsetting.svg'
import arrowIcon from '../../assets/icons/ic_arrowright.svg'
import IconTint from 'react-icon-tint';
import { Switch } from '@headlessui/react'
import { useAtom, useSetAtom } from 'jotai'
import { appVersion, currentRobot, kioskTimeout, locationArray, selectedChargePercent, selectedChargedPercent, selectedKioskLoc, selectedWarningPercent, showChargePopup, showChargedPopup, showKioskLocations, showKioskTimeout, showWarningPopup } from '../store/DataStore'
import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase-config'
import CounterPopup from '../popups/CounterPopup'
import LocationsPopup from '../popups/LocationsPopup'
import ukIcon from '../../assets/icons/ic_uk.png'
import frIcon from '../../assets/icons/ic_fr.png'
import spIcon from '../../assets/icons/ic_spain.png'

const SettingsFragment = () => {
  const [savedRobot] = useAtom(currentRobot);
  const docPathBattery = `Companies/Zeeno_Dev/Settings/${savedRobot}/Battery/batterysetting`;
  const docRefBattery = doc(db, docPathBattery);

  const [isKioskTimeoutOpen, setIsKioskTimeoutOpen] = useAtom(showKioskTimeout);
  const [kioskLocationStatus, setKioskLocationStatus] = useAtom(showKioskLocations)
  const [currentTimeout, setCurrentTimeout] = useAtom(kioskTimeout)
  const [locArray] = useAtom(locationArray)
  const [selectedKioskLocation, setSelectedKioskLocation] = useAtom(selectedKioskLoc);

  const [batteryWarningPercent, setBatteryWarningPercent] = useAtom(selectedWarningPercent);
  const [isWarningPopup, setIsWarningPopup] = useAtom(showWarningPopup);

  const [batteryChargePercent, setBatteryChargePercent] = useAtom(selectedChargePercent);
  const [isChargePopup, setIsChargePopup] = useAtom(showChargePopup);

  const [batteryChargedLevel, setBatteryChargedLevel] = useAtom(selectedChargedPercent);
  const [isChargedPopup, setIsChargedPopup] = useAtom(showChargedPopup);


  const onKioskTimeoutStart = async () => {
    setIsKioskTimeoutOpen(false);
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        timeout: currentTimeout
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const selectKioskLocation = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        location: selectedKioskLocation
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }


  const onBatteryWarningApply = async () => {
    try {
      await updateDoc(docRefBattery, {
        warninglevel_percent: batteryWarningPercent
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onBatteryChargeApply = async () => {
    try {
      await updateDoc(docRefBattery, {
        chargelevel_percent: batteryChargePercent
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onBatteryChargedApply = async () => {
    try {
      await updateDoc(docRefBattery, {
        chargedlevel_percent: batteryChargedLevel
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  return (
    <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto'>
      <CounterPopup name='timeout' type={'sec'} currentNum={currentTimeout} setCurrentNum={setCurrentTimeout} isOpen={isKioskTimeoutOpen} onClose={() => setIsKioskTimeoutOpen(false)} onSend={() => onKioskTimeoutStart()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Kiosk Timeout</h2>
      </CounterPopup>
      <LocationsPopup selectedLocation={selectedKioskLocation} setSelectedLocation={setSelectedKioskLocation} isOpen={kioskLocationStatus} onClose={() => setKioskLocationStatus(false)} onSend={() => selectKioskLocation()} locations={locArray}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Kiosk Location</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">Please choose a kiosk location</p>
      </LocationsPopup>
      <CounterPopup name='battery' type={'percent'} currentNum={batteryWarningPercent} setCurrentNum={setBatteryWarningPercent} isOpen={isWarningPopup} onClose={() => setIsWarningPopup(false)} onSend={() => onBatteryWarningApply()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Battery Warning Level</h2>
      </CounterPopup>
      <CounterPopup name='battery' type={'percent'} currentNum={batteryChargePercent} setCurrentNum={setBatteryChargePercent} isOpen={isChargePopup} onClose={() => setIsChargePopup(false)} onSend={() => onBatteryChargeApply()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Recharge Battery Level</h2>
      </CounterPopup>
      <CounterPopup name='charged' type={'percent'} currentNum={batteryChargedLevel} setCurrentNum={setBatteryChargedLevel} isOpen={isChargedPopup} onClose={() => setIsChargedPopup(false)} onSend={() => onBatteryChargedApply()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">End Recharge Battery Level</h2>
      </CounterPopup>
      <Header />
      <div className='flex flex-col w-full 2xl:mt-14 lg:mt-8'>
        <h1 className='font-heebo text-xl  text-[#273a84]'>Settings</h1>
        <SettingsMenu />
      </div>

    </div>
  )
};
export default SettingsFragment

const Dropdown = ({ icon, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-3">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer flex items-center justify-between w-full text-left text-xl font-heebo bg-white ${isOpen ? 'rounded-t-lg' : 'rounded-lg hover:bg-[#fbfbff]'
          } focus:outline-none transition duration-300`}
      >
        <div className={`transition-all py-[12px] pl-3 pr-2 ${isOpen
          ? 'bg-[#3C5BD2] rounded-tl-lg rounded-br-lg border-r-4 border-[#3C5BD2] rounded-tr-sm rounded-bl-sm'
          : 'border-r-4 border-[#F0F2FF]'
          }`}>
          <IconTint src={icon} alt='icon' className='h-7 w-7 object-contain' color={isOpen ? '#FFFF' : '#3C5BD2'} />
        </div>
        <div className='flex flex-row w-full pl-4 pr-5 items-center'>
          <span className='w-full'>{title}</span>
          <img src={arrowIcon} alt='arrow' className={`h-4 transition-transform duration-300 ${isOpen ? 'transform -rotate-90' : ''}`} />
        </div>
      </div>
      <div className={`${isOpen ? 'max-h-[1500px]' : 'max-h-0'
        } overflow-hidden transition-max-height duration-700 ease-in-out`}>
        <div className="p-4 bg-white rounded-b-lg">
          {children}
        </div>
      </div>
    </div>
  );
};

const SettingsMenu = () => {
  return (
    <div className="w-full mt-5">
      <Dropdown icon={speakerIcon} title="Audio">
        <AudioSetting />
      </Dropdown>
      <Dropdown icon={hardwareIcon} title="Hardware & Display">
        <HardwareSetting />
      </Dropdown>
      <Dropdown icon={integrationIcon} title="Zeeno AI">
        <IntegrationSetting />
      </Dropdown>
      <Dropdown icon={kioskIcon} title="Kiosk">
        <KioskSetting />
      </Dropdown>
      <Dropdown icon={actionsIcon} title="Actions">
        <ActionsSetting />
      </Dropdown>
      <Dropdown icon={batteryIcon} title="Battery & Charge">
        <BatterySetting />
      </Dropdown>
      <Dropdown icon={langIcon} title="Languages">
        <LanguageSetting />
      </Dropdown>
      <Dropdown icon={settingIcon} title="Advanced Settings">
        <AdvancedSetting />
      </Dropdown>
    </div>
  );
};

const AudioSetting = () => {
  const [volume, setVolume] = useState(50);
  const [isVoiceCommand, setIsVoiceCommand] = useState(true);
  const [isVoiceAckno, setIsVoiceAckno] = useState(false);
  const [isObsNotif, setIsObsNotif] = useState(false);
  const [savedRobot] = useAtom(currentRobot);

  useEffect(() => {
    async function getAudioData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (typeof data.volume_amount !== 'undefined' && data.volume_amount !== null) {
            setVolume(data.volume_amount * 10);
          }
          if (data.toggle_voicecommand != null && data.toggle_voiceackno != null && data.toggle_obsnotif != null) {
            setIsVoiceCommand(data.toggle_voicecommand);
            setIsVoiceAckno(data.toggle_voiceackno);
            setIsObsNotif(data.toggle_obsnotif);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getAudioData();
  }, [savedRobot])


  const sendVolumeAmount = async (value) => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        volume_amount: value
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
    sendVolumeAmount(volume / 10)
  };

  const sendCommands = async (voiceCommand, voiceAckno, obsNotif) => {
    if (savedRobot != null) {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
      const docRef = doc(db, docPath);
      try {
        await updateDoc(docRef, {
          toggle_voicecommand: voiceCommand,
          toggle_voiceackno: voiceAckno,
          toggle_obsnotif: obsNotif
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }

  const onVoiceCommandChanged = (checked) => {
    setIsVoiceCommand(checked);
    sendCommands(checked, isVoiceAckno, isObsNotif);
  }

  const onVoiceAcknoChanged = (checked) => {
    setIsVoiceAckno(checked);
    sendCommands(isVoiceCommand, checked, isObsNotif);
  }

  const onObsNotifChanged = (checked) => {
    setIsObsNotif(checked);
    sendCommands(isVoiceCommand, isVoiceAckno, checked);
  }

  return (
    <div className="w-full">
      <h1 className='text-lg font-heebo'>Volume</h1>
      <div className='flex flex-row items-center mt-3'>
        <img src={muteIcon} alt='mute' className='h-5 object-contain' />
        <input
          type="range"
          min="0"
          max="100"
          value={volume}
          onChange={handleVolumeChange}
          className="w-full h-2 bg-[gray-200] rounded-md cursor-pointer appearance-none ml-3"
          style={{
            backgroundImage: `linear-gradient(to right, #3B82F6 ${volume}%, #8290F83A ${volume}%)`
          }}
        />
        <img src={speakerIcon} alt='mute' className='h-5 object-contain ml-3 filter grayscale contrast-200 brightness-0' />
      </div>
      <hr className='mt-6 opacity-60' />

      <div className={`flex flex-row w-full mt-4 justify-between items-center ${(isVoiceAckno || isObsNotif) && 'opacity-40'}`}>
        <h1 className='text-lg font-heebo '>Voice Commands</h1>
        <Switch
          checked={isVoiceCommand}
          disabled={(isVoiceAckno || isObsNotif)}
          onChange={(value) => onVoiceCommandChanged(value)}
          className={`${isVoiceCommand ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 ${!(isVoiceAckno || isObsNotif) && 'cursor-pointer'} rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isVoiceCommand ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light ${(isVoiceAckno || isObsNotif) && 'opacity-40'}`}>Determines whenever the voice command button on Zeeno is enabled</span>
      <hr className='mt-4 opacity-60' />

      <div className={`flex flex-row w-full mt-4 justify-between items-center ${isVoiceCommand && 'opacity-40'}`}>
        <h1 className='text-lg font-heebo '>Voice Acknowledgement</h1>
        <Switch
          checked={isVoiceAckno}
          disabled={isVoiceCommand}
          onChange={(value) => onVoiceAcknoChanged(value)}
          className={`${isVoiceAckno ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 ${!isVoiceCommand && 'cursor-pointer'} rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isVoiceAckno ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light ${isVoiceCommand && 'opacity-40'}`}>Zeeno will automatically listen for voice commands after requesting patient's acknowledgement</span>
      <hr className='mt-4 opacity-60' />

      <div className={`flex flex-row w-full mt-4 justify-between items-center ${isVoiceCommand && 'opacity-40'}`}>
        <h1 className='text-lg font-heebo '>Obstacle Notification</h1>
        <Switch
          checked={isObsNotif}
          disabled={isVoiceCommand}
          onChange={(value) => onObsNotifChanged(value)}
          className={`${isObsNotif ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 ${!isVoiceCommand && 'cursor-pointer'} rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isObsNotif ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light ${isVoiceCommand && 'opacity-40'}`}>Zeeno will beep when encountering obstacles while navigating</span>
    </div>
  );
};

const HardwareSetting = () => {
  const [isHardwareDisabled, setIsHardwareDisabled] = useState(false);
  const [isDisplayFace, setIsDisplayFace] = useState(false);
  const [savedRobot] = useAtom(currentRobot);

  useEffect(() => {
    async function getData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.toggle_hardwaredisable != null) {
            setIsHardwareDisabled(data.toggle_hardwaredisable);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getData();
  }, [savedRobot])

  const sendCommands = async (hardwareDisable, displayFace) => {
    if (savedRobot != null) {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
      const docRef = doc(db, docPath);
      try {
        await updateDoc(docRef, {
          toggle_hardwaredisable: hardwareDisable,
          toggle_displayface: displayFace,
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }

  const onHardwareDisabledChanged = (checked) => {
    setIsHardwareDisabled(checked)
    sendCommands(checked, isDisplayFace);
  }

  const onDisplayFace = (checked) => {
    setIsDisplayFace(checked)
    sendCommands(isHardwareDisabled, checked);
  }

  return (
    <div className='w-full'>
      <div className={`flex flex-row w-full justify-between items-center`}>
        <h1 className='text-lg font-heebo'>Disable Hardware Buttons</h1>
        <Switch
          checked={isHardwareDisabled}
          onChange={(value) => onHardwareDisabledChanged(value)}
          className={`${isHardwareDisabled ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isHardwareDisabled ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-3xl`}>Disables the external hardware buttons on the top of the tablet - It is recommended to switch this setting on to avoid external input from messing with app dynamics.</span>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>Display</h1>
        <Switch
          checked={isDisplayFace}
          onChange={(value) => onDisplayFace(value)}
          className={`${isDisplayFace ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isDisplayFace ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-3xl`}>Determines whether or not Zeeno will display a kid friendly face during patient interactive actions.</span>

    </div>
  )
}

const IntegrationSetting = () => {
  const [savedRobot] = useAtom(currentRobot);
  const [savedPrompt, setSavedPrompt] = useState('');
  const [savedKnowledge, setSavedKnowledge] = useState('');
  const [isSavedForm, setIsSavedForm] = useState(false);
  const [isFromEmpty, setIsFromEmpty] = useState(false);

  const onSavedPressed = async () => {
    setIsSavedForm(true);
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/bot/prompt`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        content: savedPrompt,
        knowledge: savedKnowledge,
      });
    } catch (error) {
      console.error('error updating prompt: ', error);
    }
  }

  useEffect(() => {
    if (!savedRobot || savedRobot.trim() === '') {
      console.log("No robot saved");
      return;
    }

    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/bot/prompt`;
    const docRef = doc(db, docPath);

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const fetchedPrompt = data.content;
        const fetchedKnowledge = data.knowledge;
        
        setSavedPrompt(fetchedPrompt);
        setSavedKnowledge(fetchedKnowledge);
      } else {
        console.log("Document does not exist!");
      }
    }, (error) => {
      console.error("Error listening to document: ", error);
    });
  }, [savedRobot])
  

  useEffect(() => {
    if(savedPrompt === ""){
      setIsFromEmpty(true);
    } else {
      setIsFromEmpty(false);
    }
  }, [savedPrompt, savedKnowledge])
  
  return (
    <div>
      <h1 className='text-lg font-heebo'>ZeenoAI Prompt</h1>
      <textarea value={savedPrompt} onChange={(e) => {setSavedPrompt(e.target.value); setIsSavedForm(false);}} placeholder='Type prompt here...' className='h-44 bg-[#DCE0FF] p-4 rounded-lg outline-none resize-none font-heebo w-[670px] font-light mt-2'></textarea>
      <h1 className='text-lg font-heebo mt-2'>Zeeno's Knowledge</h1>
      <textarea value={savedKnowledge} onChange={(e) => {setSavedKnowledge(e.target.value); setIsSavedForm(false);}} placeholder='Type Knowledge here...' className='h-36 bg-[#DCE0FF] p-4 rounded-lg outline-none resize-none font-heebo w-[670px] font-light mt-2'></textarea>
      <h2
        onClick={() => {
          if(isSavedForm === false && isFromEmpty === false){
            onSavedPressed();
          }
        }}
        className={`px-5 py-2 ${isSavedForm || isFromEmpty ? 'bg-opacity-40' : 'hover:bg-opacity-100 bg-opacity-80 cursor-pointer'} bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3`}>{isSavedForm ? 'Saved' : 'Save'}</h2>
    </div>
  )
}

const KioskSetting = () => {
  const [isKioskMode, setIsKioskMode] = useState(false);
  const [isKioskAction, setIsKioskAction] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [savedRobot] = useAtom(currentRobot);
  const setIsKioskTimeoutOpen = useSetAtom(showKioskTimeout);

  const [kioskSavedTimeout, setKioskSavedTimeout] = useAtom(kioskTimeout);
  const setKioskLocationStatus = useSetAtom(showKioskLocations);
  const setlocArray = useSetAtom(locationArray);

  const [selectedLocation, setSelectedLocation] = useAtom(selectedKioskLoc);



  async function getLocationList(db) {
    if (savedRobot !== '') {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Locations/backuplocations`;
      const docRef = doc(db, docPath);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const locations = docSnap.data().locations;
          setlocArray(locations);
          return locations;
        } else {
          console.log("No such document!");
          return [];
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        return [];
      }
    } else {
      console.log("No robot saved");
      return [];
    }
  }

  useEffect(() => {
    getLocationList(db);
    async function getTimeoutData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.toggle_timeout != null) {
            setIsTimeout(data.toggle_timeout);
          }
          if (data.timeout != null) {
            setKioskSavedTimeout(data.timeout);
          }
          if (data.location != null) {
            setSelectedLocation(data.location);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getTimeoutData();
    async function getData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.toggle_kioskmode != null) {
            setIsKioskMode(data.toggle_kioskmode);
          }
          if (data.toggle_kioskaction != null) {
            setIsKioskAction(data.toggle_kioskaction);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRobot])

  const sendCommands = async (kioskMode, kioskAction) => {
    if (savedRobot != null) {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
      const docRef = doc(db, docPath);
      try {
        await updateDoc(docRef, {
          toggle_kioskmode: kioskMode,
          toggle_kioskaction: kioskAction,
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }

  const onKioskModeChanged = (checked) => {
    setIsKioskMode(checked)
    sendCommands(checked, isKioskAction)
  }

  const onKioskActionChanged = (checked) => {
    setIsKioskAction(checked);
    sendCommands(isKioskMode, checked);
  }

  const onTimeoutChanged = async (checked) => {
    setIsTimeout(checked);
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_timeout: checked
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  return (
    <div className='w-full'>
      <div className={`flex flex-row w-full justify-between items-center`}>
        <h1 className='text-lg font-heebo'>Kiosk Mode</h1>
        <Switch
          checked={isKioskMode}
          onChange={(value) => onKioskModeChanged(value)}
          className={`${isKioskMode ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isKioskMode ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-3xl`}>Will activate Kiosk mode</span>

      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center`}>
        <h1 className='text-lg font-heebo mt-4'>Kiosk Timeout</h1>
        <Switch
          checked={isTimeout}
          onChange={(value) => onTimeoutChanged(value)}
          className={`${isTimeout ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isTimeout ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light max-w-3xl`}>Amount of time Zeeno must be idle before automatically submitting the kiosk action.</span>
      <h2 className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer' onClick={() => setIsKioskTimeoutOpen(true)}>{`${kioskSavedTimeout} sec`}</h2>

      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>Kiosk Action</h1>
        <Switch
          checked={isKioskAction}
          onChange={(value) => onKioskActionChanged(value)}
          className={`${isKioskAction ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isKioskAction ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-3xl`}>Behavior to be executed while in kiosk mode.</span>

      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Kiosk Location</h1>
      <span className={`font-heebo font-light`}>Location at which to perform the kiosk action (i.e. navigate to this location and then perform the kiosk action)</span>
      <h2 onClick={() => setKioskLocationStatus(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{selectedLocation}</h2>
    </div>
  )
}

const ActionsSetting = () => {
  return (
    <div>
      Actions Content
    </div>
  )
}

const BatterySetting = () => {
  const [savedRobot] = useAtom(currentRobot);

  const setOpenWarningPopup = useSetAtom(showWarningPopup);
  const [warningLevel, setWarningLevel] = useAtom(selectedWarningPercent);

  const setOpenChargePopup = useSetAtom(showChargePopup);
  const [chargeLevel, setChargeLevel] = useAtom(selectedChargePercent);

  const setOpenChargedPopup = useSetAtom(showChargedPopup);
  const [chargedLevel, setChargedLevel] = useAtom(selectedChargedPercent);
  const [isCharged, setIsCharged] = useState(false);


  useEffect(() => {
    async function getData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Battery/batterysetting`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.warninglevel_percent != null) {
            setWarningLevel(data.warninglevel_percent)
          }
          if (data.chargelevel_percent != null) {
            setChargeLevel(data.chargelevel_percent)
          }
          if (data.chargedlevel_percent != null) {
            setChargedLevel(data.chargedlevel_percent)
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRobot])


  return (
    <div className='flex flex-col'>
      <h1 className='text-lg font-heebo'>Low Battery Warning</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>At this battery percent, Zeeno will announce that battery is low and it will soon have to automatically return to home base to charge</span>
      <h2 onClick={() => setOpenWarningPopup(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{`${warningLevel}%`}</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Recharge Battery Level</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>At this battery percent, Zeeno will automatically place the “charge” task on the queue. Practically, this means that Zeeno will continue to complete all currently assigned tasks and afterwards head to home base to charge.</span>
      <h2 onClick={() => setOpenChargePopup(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{`${chargeLevel}%`}</h2>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>End Recharge Battery Level</h1>
        <Switch
          checked={isCharged}
          onChange={(value) => setIsCharged(value)}
          className={`${isCharged ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isCharged ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>While the charging action is enabled Zeeno will accept additional goals, but will not execute them until the charge behavior is acknowledged, canceled, or the battery reaches this set threshold - upon which point the charge task will automatically terminate.</span>
      <h2 onClick={() => setOpenChargedPopup(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{`${chargedLevel}%`}</h2>
    </div>
  )
}

const LanguageSetting = () => {
  const languages = [
    { code: ukIcon, label: 'English (UK)' },
    { code: frIcon, label: 'Français' },
    { code: spIcon, label: 'Español' },
  ];
  // Initialize the state with the first language selected
  const [selectedLanguages, setSelectedLanguages] = useState({
    [languages[0].code]: true,
  });

  const toggleLanguage = (selectedCode) => {
    setSelectedLanguages(prevSelected => {
      // Create a new object with all values set to false
      const resetLanguages = Object.keys(prevSelected).reduce((acc, code) => {
        acc[code] = false;
        return acc;
      }, {});

      // Set only the selected language to true
      return { ...resetLanguages, [selectedCode]: true };
    });
  };

  return (
    <div className="flex flex-col">
      {languages.map((language) => (
        <label key={language.code} className={`flex items-center space-x-3 ${(selectedLanguages[language.code] || false) && 'bg-slate-50'} p-3 cursor-pointer rounded-lg`}>
          <input
            type="checkbox"
            checked={selectedLanguages[language.code] || false}
            onChange={() => toggleLanguage(language.code)}
            className="form-checkbox h-5 w-5 mr-2 ml-2 cursor-pointer"
          />
          <img
            src={language.code}
            alt={`${language.label} Flag`}
            className="h-10 w-16 object-contain"
          />
          <span className="text-gray-700 font-heebo text-xl">{language.label}</span>
        </label>
      ))}
    </div>
  )
}

const AdvancedSetting = () => {
  const [isTopBar, setIsTopBar] = useState(null);
  const [isReqPerm, setIsReqPerm] = useState(null);
  const [savedRobot] = useAtom(currentRobot);
  const [appVersionSaved, setAppVersionSaved] = useAtom(appVersion);

  const onRestartClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_restart: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onUpdateMapClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_updatemap: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onBackupLocClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_backuploc: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onRestoreLocClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_restoreloc: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onChangeTopBar = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Advanced/advancedsetting`;
    const docRef = doc(db, docPath);

    if (isTopBar) {
      try {
        await updateDoc(docRef, {
          toggle_topbar: true
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    } else {
      try {
        await updateDoc(docRef, {
          toggle_topbar: false
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }

  const onChangeRequestPerms = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Advanced/advancedsetting`;
    const docRef = doc(db, docPath);

    if (isReqPerm) {
      try {
        await updateDoc(docRef, {
          toggle_reqperms: true
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    } else {
      try {
        await updateDoc(docRef, {
          toggle_reqperms: false
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }

  useEffect(() => {
    async function getData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Advanced/advancedsetting`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.toggle_topbar != null) {
            setIsTopBar(data.toggle_topbar)
          }
          if (data.toggle_reqperms != null) {
            setIsReqPerm(data.toggle_reqperms)
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRobot])


  useEffect(() => {
    if (isTopBar != null) {
      onChangeTopBar();
    }
    if (isReqPerm != null) {
      onChangeRequestPerms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTopBar, isReqPerm])


  useEffect(() => {
    async function getVerData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.app_version != null) {
            setAppVersionSaved(data.app_version);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getVerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRobot])

  return (
    <div>
      <h1 className='text-lg font-heebo'>Send Logs</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Pressing this button sends Zeeno’s internal logs to the server. It is recommended to press this button when an issue occurs so information on the problem can be gathered and addressed quickly.</span>
      <h2 onClick={() => console.log("sent logs")} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Send Logs</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Restart App</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Pressing this button restarts the app, leaving it in a fresh state. It is recommended to press this button if a breaking issue occurs which leaves the user unable to issue commands to Zeeno.</span>
      <h2 onClick={() => onRestartClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Restart</h2>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>Show Top Bar</h1>
        <Switch
          checked={isTopBar}
          onChange={(value) => setIsTopBar(value)}
          className={`${isTopBar ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isTopBar ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>By activating this toggle pulls down the top bar of the native tablet, this can be used in case the user desires to navigate external to the app for whatever reason.</span>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Update Map</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Pressing this button sends a copy of the map to Zeeno Robotics server. Should be used if the map on the webapp does not match the one displayed on the robot</span>
      <h2 onClick={() => onUpdateMapClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Update Map</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Backup Locations</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Saves all currently saved locations to a collection and backs it up on the server</span>
      <h2 onClick={() => onBackupLocClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Backup Locations</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Restore Locations</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Overwrites all existing locations and replaces them with the backed up collection</span>
      <h2 onClick={() => onRestoreLocClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Restore Locations</h2>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>Request Permissions</h1>
        <Switch
          checked={isReqPerm}
          onChange={(value) => setIsReqPerm(value)}
          className={`${isReqPerm ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isReqPerm ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>By activating this toggle, requests necessary permissions (e.g. microphone) for the app to function properly. Useful in case permissions are set incorrectly during setup or revoked for whatever reason.</span>
      <hr className='mt-4 opacity-60' />
      <div className='flex flex-row py-5 w-full justify-between pr-4'>
        <span className='text-xl font-heebo'>Serial Number</span>
        <span className='text-xl font-heebo font-light text-[#6879ff]'>{savedRobot != null ? savedRobot : 'Open Robot'}</span>
      </div>
      <hr className='opacity-10 border-t border-[#6879ff]' />
      <div className='flex flex-row pt-5 pb-2 w-full justify-between pr-4'>
        <span className='text-xl font-heebo'>App Version</span>
        <span className='text-xl font-heebo font-light text-[#6879ff]'>{appVersionSaved != null ? appVersionSaved : 'Open App'}</span>
      </div>
    </div>
  )
}