import React, { useEffect, useState } from 'react';
import escortIcon from '../../assets/icons/ic_escort.svg'
import locpinIcon from '../../assets/icons/ic_locpin.svg';
import IconTint from 'react-icon-tint';
import Dropdown from '../Dropdown';
import { useAtom } from 'jotai';
import { fetchFrom, fetchItem, fetchWaitTime } from '../store/DataStore';


const waitTimeArr = ['Wait time', '30 sec', '60 sec', '90 sec']

const FetchPopup = ({ isOpen, children, onClose, onSend, locations }) => {
    const [show, setShow] = useState(isOpen);
    const [isDropdownTouched, setIsDropdownTouched] = useState(false)
    const [selectedWaitTime, setSelectedWaitTime] = useAtom(fetchWaitTime);
    const [selectedFromLocation, setSelectedFromLocation] = useAtom(fetchFrom);
    const [itemFetched, setItemFetched] = useAtom(fetchItem);


    useEffect(() => {
        if (isOpen) {
            setShow(true);
            if (!isDropdownTouched) {
                setSelectedWaitTime(waitTimeArr[2]);
            }
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
        setItemFetched('');
        setSelectedWaitTime('60 sec');
        setSelectedFromLocation('');
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
        setItemFetched('');
        setSelectedWaitTime('60 sec');
        setSelectedFromLocation('');
    }

    const setSelected = (item) => {
        setIsDropdownTouched(true);
        setSelectedWaitTime(item);
        console.log("selected to " + item)
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-[full] transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
                <div className='flex flex-row gap-4 mt-2 mb-5'>
                    <input className='bg-[#3C5BD2] bg-opacity-10 px-2 py-4 h-7 mt-1 rounded-md w-[350px] outline-none font-heebo font-light' placeholder='Item description' value={itemFetched} onChange={(e) => setItemFetched(e.target.value)} />
                    <Dropdown list={waitTimeArr} setSelected={(item) => setSelected(item)} selectedItem={selectedWaitTime} dropdownWidth={"150"} />
                </div>
                <span className='font-heebo font-light'>Where from?</span>
                <div className="grid grid-cols-3 gap-4 mt-2">
                    {locations.map((item, i) => (
                        <div key={i} className={`flex items-center p-1 w-[150px] border-l-2 border-l-[#8290F8] ${selectedFromLocation === item ? 'bg-[#3C5BD2]' : 'bg-[#DCE0FF]'} rounded-l-3xl rounded-r-md cursor-pointer`} onClick={() => setSelectedFromLocation(item)}>
                            {selectedFromLocation === item ?
                                <IconTint src={locpinIcon} className='h-5' color='#FFFFFF' /> :
                                <IconTint src={locpinIcon} className='h-5' color='#152FF5' />
                            }
                            <div className={`ml-2 font-heebo font-light ${selectedFromLocation === item ? 'text-white' : 'text-black'}`}>{item}</div>
                        </div>
                    ))}
                </div>
                <div className='w-full flex justify-center mt-14'>
                    {(selectedFromLocation!=="" && selectedWaitTime!=="Wait time" && itemFetched!=='') ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                            <span className='text-white font-heebo'>Confirm Fetch</span>
                            <IconTint src={escortIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Confirm Fetch</span>
                            <IconTint src={escortIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default FetchPopup;
