import React, { useEffect, useRef, useState } from 'react';
import IconTint from 'react-icon-tint';
import downIcon from '../../assets/icons/ic_arrowdown.svg';
import upIcon from '../../assets/icons/ic_arrowup.svg';
import { collection, doc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase-config';

const EditUserPopup = ({ isOpen, user, onClose, onUpdate }) => {
    // Check if user is defined and set default values if not
    const [show, setShow] = useState(isOpen);
    const [fullName, setFullName] = useState(user ? user.name : '');
    const [email] = useState(user ? user.email : '');
    const [phoneNumber, setPhoneNumber] = useState(user ? user.phone : '');
    const [role, setRole] = useState(user ? user.role : '');

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleUpdate = async () => {
        if (user) {
            const usersCollectionRef = collection(db, 'Companies/Zeeno_Dev/Users');
            const userId = user.id;
            const userQuery = query(usersCollectionRef, where('id', '==', userId));
            let userIDToupdate = '';
        
            const querySnapshot = await getDocs(userQuery);
            querySnapshot.forEach((doc) => {
                userIDToupdate = doc.id;
            });
            console.log('Updating user: ', { name: fullName, phone: phoneNumber, role });
            console.log('User ID: ', userIDToupdate);
            try {
                // Update Firestore document
                //const userId = user.id;
                // const userDocRef = doc(db, 'Companies/Zeeno_Dev/Users/${userId}', userId);
                const userDocRef = doc(db, `Companies/Zeeno_Dev/Users/${userIDToupdate}`);
                await updateDoc(userDocRef, {
                    name: fullName,
                    phone: phoneNumber,
                    role: role
                });

                console.log('User updated successfully');
                
                // Call the onUpdate callback if provided
                onUpdate({ ...user, name: fullName, phone: phoneNumber, role });
            } catch (error) {
                console.error('Error updating user:', error);
            }

            setShow(false);
            setTimeout(onClose, 200);
        }
    };

    if (!user) {
        // Optionally render a loading state or a message if user is undefined
        console.error('User is not defined');
        return null;
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'} z-20`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Edit User</h2>
                </div>
                <div className='flex flex-row gap-4'>
                    <div>
                        <div className='flex flex-col mt-3 gap-1'>
                            <span className='font-heebo font-light'>Full Name</span>
                            <input 
                                value={fullName} 
                                onChange={(e) => setFullName(e.target.value)} 
                                className='font-heebo font-light w-56 bg-[#e1e6fd] p-1 px-2 rounded-md items-center outline-none' 
                                placeholder='Type your name' 
                            />
                        </div>
                        <div className='flex flex-col mt-6 gap-1'>
                            <span className='font-heebo font-light'>Email Address</span>
                            <input 
                                disabled={true} 
                                value={email} 
                                className='font-heebo font-light w-56 bg-[#e1e6fd] p-1 px-2 rounded-md items-center outline-none' 
                                placeholder='Type your email' 
                            />
                        </div>
                    </div>
                    <div>
                        <div className='flex flex-col mt-3 gap-1'>
                            <span className='font-heebo font-light'>Access</span>
                            <TypeDropdown selectedRole={role} onSelectedSaved={setRole} />
                        </div>
                        <div className='flex flex-col mt-6 gap-1'>
                            <span className='font-heebo font-light'>Phone Number</span>
                            <input 
                                value={phoneNumber} 
                                onChange={(e) => setPhoneNumber(e.target.value)} 
                                className='font-heebo font-light w-56 bg-[#e1e6fd] p-1 px-2 rounded-md items-center outline-none' 
                                placeholder='phone number' 
                                type='tel' 
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center mt-14'>
                    <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleUpdate}>
                        <span className='text-white font-heebo'>Update User</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TypeDropdown = ({ selectedRole, onSelectedSaved }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState(selectedRole);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const myComponentRef = useRef(null);
    const list = ['User', 'Admin'];

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (myComponentRef.current && !myComponentRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div ref={myComponentRef} className="relative w-fit transition-all delay-200 select-none">
            <div onClick={toggleDropdown} className={`cursor-pointer w-56 min-w-[110px] pl-1 pr-2 py-1 bg-[#e1e6fd] bg-opacity-60  ${isOpen ? 'rounded-bl-none rounded-b-none rounded-xl' : 'rounded-lg  hover:bg-opacity-40'}  focus:outline-none flex flex-row items-center justify-between`}>
                <span className='truncate text-[#3C5BD2] font-heebo ml-2 font-normal w-full text-ellipsis'>{selected}</span>
                {!isOpen ? <IconTint src={downIcon} className='h-3' color='#3C5BD2' /> : <IconTint src={upIcon} className='h-3 -mt-1' color='#3C5BD2' />}
            </div>
            {isOpen && (
                <div className="absolute left-0 z-10 w-full py-2 bg-[#e1e6fd] rounded-b-md shadow-xl ">
                    {list.map((item, index) => (
                        <span
                            key={index}
                            className="block px-4 py-2 text-sm text-[#3C5BD2] cursor-pointer font-heebo hover:bg-[#b9c7ff44]"
                            onClick={() => {
                                setSelected(item);
                                onSelectedSaved(item);
                                setIsOpen(false);
                            }}
                        >{`${item}`}</span>
                    ))}
                </div>
            )}
        </div>
    );
};

export default EditUserPopup;
