import React, { useEffect, useState } from 'react';
import uploadIcon from '../../assets/icons/ic_upload.svg'
import uploadedIcon from '../../assets/icons/ic_uploaded.svg'
import trashIcon from '../../assets/icons/ic_trash.svg'
import { collection, doc, setDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase-config';
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { currentRobot } from '../store/DataStore';
import { useAtom } from 'jotai';



const AddVideoPopup = ({ isOpen, children, onClose, onAdd }) => {
    const [show, setShow] = useState(isOpen);
    const [dragOver, setDragOver] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [titleInput, setTitleInput] = useState('');
    const [currRobot] = useAtom(currentRobot);
    const [isLoading, setIsLoading] = useState(false);

    const extractThumbnailAndDuration = (file) => {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            const url = URL.createObjectURL(file);
            let durationFormatted = ''; // Declare outside the event handler

            video.src = url;
            video.addEventListener('loadedmetadata', () => {
                const duration = video.duration;
                const minutes = Math.floor(duration / 60);
                const seconds = Math.floor(duration % 60);
                durationFormatted = `${minutes}:${seconds.toString().padStart(2, '0')}`;

                // Set the time for the thumbnail (1 second into the video)
                video.currentTime = 5;
            });

            video.addEventListener('seeked', () => {
                const canvas = document.createElement('canvas');
                const scale = 0.25;
                canvas.width = video.videoWidth * scale;
                canvas.height = video.videoHeight * scale;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

                // Convert canvas to data URL
                const thumbnailDataUrl = canvas.toDataURL('image/png');
                resolve({ durationFormatted, thumbnailDataUrl });
            });

            video.addEventListener('error', (e) => {
                reject('Error processing video file.');
            });
        });
    };

    const uploadVideo = async (file, title, type) => {
        const vidRef = ref(storage, `Zeeno/${title}.${type}`);
        try {
            const snapshot = await uploadBytes(vidRef, file);
            // console.log('Uploaded a blob or file!');
            const downloadURL = await getDownloadURL(vidRef);
            console.log('Download URL:', downloadURL);
            console.log('Snapshot is:', snapshot);
            return downloadURL;
        } catch (error) {
            console.error('Upload failed', error);
            throw new Error(error);
        }
    }

    const addNewVideo = async (file, title) => {
        try {
            const { durationFormatted, thumbnailDataUrl } = await extractThumbnailAndDuration(file);
            uploadVideo(file, title, file.type.slice(-3))
            const downloadURL = await uploadVideo(file, title, file.type.slice(-3));
            const docPath = `Companies/Zeeno_Dev/Videos`;
            // const docRef = doc(db, docPath);
            const uniqueVideoId = doc(collection(db, docPath)).id;
            const robotArray = [currRobot]

            const videoDocRef = doc(db, docPath, uniqueVideoId);
            await setDoc(videoDocRef, {
                robots: robotArray,
                video_length: durationFormatted,
                video_name: title,
                video_thumbnail: thumbnailDataUrl,
                video_url: downloadURL
            });
            setShow(false);
            setTimeout(onClose, 200);
            onAdd();
        } catch (error) {
            console.error(error);
        }
        console.log(file);
        setTitleInput("");
        setSelectedFile(null);
    }

    const handleClose = () => {
        setTitleInput("");
        setSelectedFile(null);
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleAdd = () => {
        setIsLoading(true); 
        addNewVideo(selectedFile, titleInput)
            .finally(() => {
                setIsLoading(false);
            });
    }
   
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setDragOver(false);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (event) => {
        event.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        setSelectedFile(file);
        setDragOver(false);
    };

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    }, [isOpen, onClose]);

    return (
        <div
            className={`z-30 fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center px-44">
                    {children}
                </div>
                <div className='flex flex-col px-20 mt-5'>
                    <span className=' font-heebo font-light text-lg'>Title that will appear on the video page</span>
                    <input type='text' value={titleInput} onChange={(e) => setTitleInput(e.target.value)} placeholder='Write Title Here' className='font-heebo font-light bg-[#8290F8] bg-opacity-20 rounded-lg py-1 px-2 text-sm w-full outline-none mt-1' />
                    {(selectedFile && !isLoading) ?
                        <div className='flex flex-col items-center p-10 border border-[#152FF5] border-opacity-10 rounded-2xl mt-6'>
                            <div className='flex flex-row '>
                                <span className='font-heebo text-lg font-normal mt-1'>{selectedFile.name}</span>
                                <img src={trashIcon} alt='delete' className='py-1 px-2 cursor-pointer' onClick={() => setSelectedFile(null)} />
                            </div>
                            <div className='flex flex-row mt-2'>
                                <img src={uploadedIcon} alt='upload' className='' />
                                <span className='ml-2 font-heebo font-light mt-1'>File uploaded successfully</span>
                            </div>
                        </div>
                        : (selectedFile && isLoading) ?
                            <div className='flex flex-col items-center p-6 border border-dashed border-[#152FF5] border-opacity-10 rounded-2xl mt-6'>
                                <div className='flex flex-col mt-2'>
                                    <LoadingDots/>
                                    <span className='ml-2 font-heebo font-light mt-5 text-gray-500'>Uploading File, Please wait...</span>
                                </div>
                            </div>
                            :
                            <label
                                htmlFor="file-upload"
                                className={`flex flex-col items-center p-10 border-dashed border border-[#152FF5] border-opacity-10 rounded-2xl mt-6 cursor-pointer ${dragOver ? 'bg-gray-300' : 'hover:bg-gray-100'}`}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                onDrop={handleDrop}
                            >
                                <input
                                    type="file"
                                    id="file-upload"
                                    className="hidden"
                                    accept=".wav, .mp4"
                                    onChange={handleFileChange}
                                />
                                <img src={uploadIcon} alt="upload" />
                                <span className="mt-3 font-heebo text-sm text-gray-500 font-light">Drag or Attach Your File Here</span>
                            </label>
                    }
                </div>
                <div className='w-full flex justify-center mt-10'>
                    {(selectedFile && titleInput !== '' && !isLoading) ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleAdd}>
                            <span className='text-white font-heebo'>Add Video</span>
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Add Video</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

const LoadingDots = () => {
    return (
      <div className="flex justify-center items-center space-x-2">
        <span className="dot animate-bounce"></span>
        <span className="dot animate-bounce200"></span>
        <span className="dot animate-bounce400"></span>
      </div>
    );
  };

export default AddVideoPopup;
