// useTasks.js
import { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase-config';

export const useTasks = (savedRobot) => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    if (!savedRobot) return;

    const collectionPath = `Companies/Zeeno_Dev/Actions/${savedRobot}/Logs`;
    const collectionRef = collection(db, collectionPath);

    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const tasksData = querySnapshot.docs.map(doc => ({
        id: doc.id, // Captures the document ID
        ...doc.data()
      })).reverse(); // Reverses the order of tasks as they are received

      setTasks(tasksData); // Updates the tasks state
    }, error => {
      console.error("Error fetching tasks: ", error);
    });

    // Function to unsubscribe from the onSnapshot listener
    return () => unsubscribe();
  }, [savedRobot]); // Effect will re-run if savedRobot changes

  return tasks; // Returns the list of tasks with their document IDs
};
