import React, { useEffect, useState } from 'react';
import chatIcon from '../../assets/icons/ic_chat.svg';
import IconTint from 'react-icon-tint';
import { useAtom } from 'jotai';
import { savedMessage } from '../store/DataStore';

const MessagePopup = ({ isOpen, children, onClose, onSend }) => {
    const [show, setShow] = useState(isOpen);
    const [messageSaved, setMessageSaved] = useAtom(savedMessage)
    
    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
        setMessageSaved("");
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
        setMessageSaved("");
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
                <textarea value={messageSaved} onChange={(e) => setMessageSaved(e.target.value)} placeholder='Type message here...' className='h-44 bg-[#DCE0FF] p-4 rounded-lg outline-none resize-none font-heebo w-[670px] font-light mt-5'></textarea>
                <div className='w-full flex justify-center mt-5'>
                    {messageSaved !== '' ? 
                    <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                        <span className='text-white font-heebo'>Display Message</span>
                        <IconTint src={chatIcon} className='h-5 ml-2' color='#FFFFFF' />
                    </div>
                    :
                    <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                        <span className='text-white font-heebo'>Display Message</span>
                        <IconTint src={chatIcon} className='h-5 ml-2' color='#FFFFFF' />
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default MessagePopup;
