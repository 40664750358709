import React, { useEffect, useState } from 'react';
import IconTint from 'react-icon-tint';
import actionsIcon from '../assets/icons/ic_actions_white.svg'
import flyerIcon from '../assets/icons/ic_flyer.svg'
import mapIcon from '../assets/icons/ic_map.svg'
import videoIcon from '../assets/icons/ic_videotape.svg'
import usersIcon from '../assets/icons/ic_users.svg'
import phoneIcon from '../assets/icons/ic_phone.svg'
import { useAtom } from 'jotai';
import { isPageChanged, selectedPage } from './store/DataStore';

const initialNavbarItems = [
    { label: "Actions", icon: actionsIcon, isSelected: true, key: 0 },
    { label: "Tasks", icon: flyerIcon, isSelected: false, key: 1 },
    { label: "Map", icon: mapIcon, isSelected: false, key: 2 },
    { label: "Video Library", icon: videoIcon, isSelected: false, key: 3 },
    { label: "Users", icon: usersIcon, isSelected: false, key: 4 },
    { label: "Support", icon: phoneIcon, isSelected: false, key: 5 },
]

const NavbarHome = () => {
    const [navbarItems, setNavbarItems] = useState(initialNavbarItems);
    return (
        <div className="w-full h-fit mt-10 px-3">
            {navbarItems.map((item, index) => (
                <NavbarHomeItem
                    key={index}
                    position={item.key}
                    icon={item.icon}
                    label={item.label}
                    setNavbarItems={setNavbarItems}
                    navbarItems={navbarItems}
                    isSelected={item.isSelected}
                />
            ))}
        </div>
    );
};

const NavbarHomeItem = ({ icon: imgSrc, label, isSelected: selected, position, setNavbarItems, navbarItems }) => {
    const [savedPos, setSavedPos] = useAtom(selectedPage);
    const [isFragmentChanged, setIsFragmentChanged] = useAtom(isPageChanged);

    useEffect(() => {
        if(savedPos === 3 && isFragmentChanged){
            selectItem(savedPos);
            setIsFragmentChanged(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFragmentChanged, savedPos])
    
    const selectItem = (index) => {
        const newItems = navbarItems.map((item, idx) => ({
            ...item,
            isSelected: idx === index,
        }));
        setNavbarItems(newItems);
        if(!isFragmentChanged){
            setSavedPos(position);
        }
    };
    return (
        <div>
            {selected && savedPos !== 6 ?
                <div className="flex items-center transition-all duration-150 2xl:py-[23px] lg:py-4 pl-9 cursor-pointer bg-white rounded-xl">
                    <IconTint src={imgSrc} color='#152FF5' />
                    <span className="ml-3 text-[#152FF5] font-heebo 2xl:text-[21px] lg:text-lg select-none">{label}</span>
                </div> :
                <div className="flex items-center transition-all duration-150 2xl:py-[23px] lg:py-4 pl-9 cursor-pointer rounded-xl hover:bg-slate-50 hover:bg-opacity-5" onClick={() => selectItem(position)}>
                     <IconTint src={imgSrc} color='#FFFFFF' />
                    <span className="ml-3 text-white font-heebo font-light 2xl:text-[21px] lg:text-lg select-none">{label}</span>
                </div>
            }
        </div>
    );
};

export default NavbarHome;




