import React, { useEffect, useState } from 'react';
import escortIcon from '../../assets/icons/ic_escort.svg'
import IconTint from 'react-icon-tint';
import Dropdown from '../Dropdown';
import { useAtom } from 'jotai';
import { currentRobot, escortFrom, escortName, escortTo } from '../store/DataStore';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';

const EscortPopup = ({ isOpen, children, onClose, onSend, locations, currentLocation }) => {
    const [show, setShow] = useState(isOpen);
    const [selectedFromSaved, setSelectedFromSaved] = useAtom(escortFrom);
    const [selectedToSaved, setSelectedToSaved] = useAtom(escortTo);
    const [selectedName, setSelectedName] = useAtom(escortName);
    const [isDropdownTouched, setIsDropdownTouched] = useState(false)
    const [savedRobot] = useAtom(currentRobot);

    useEffect(() => {
        if (isOpen) {
            console.log("My current location is " + currentLocation);
            //setSelectedFrom(currentLocation)
            setShow(true);
            if(!isDropdownTouched){
                setSelectedFromSaved(currentLocation)
                setSelectedToSaved(locations[0])
                
            }
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const setIsCurrent = async (isCurrent) => {
        const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/actions`;
        const docRef = doc(db, docPath);
        
        try {
          await updateDoc(docRef, {
            is_current: isCurrent,
          });
        } catch (error) {
          console.error('error updated location: ', error);
        }
    }

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
        setSelectedFromSaved(locations[0])
        setSelectedToSaved(locations[0])
        setSelectedName('');
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
        setSelectedFromSaved(currentLocation)
        setSelectedToSaved(locations[0])
        setSelectedName('');

        console.log("Selected from saved is " + selectedFromSaved);
        if(selectedFromSaved === currentLocation) {
            setIsCurrent(true);
        } else {
            setIsCurrent(false);
        }
    }

    const setSelectedFrom = (item) => {
        setIsDropdownTouched(true);
        setSelectedFromSaved(item);
    }

    const setSelectedTo = (item) => {
        setIsDropdownTouched(true);
        setSelectedToSaved(item);
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-[full] transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
                <input className='bg-[#3C5BD2] bg-opacity-10 p-4 h-10 mt-4 rounded-lg w-full outline-none font-heebo font-light' placeholder='Type Name...'value={selectedName} onChange={(e) => setSelectedName(e.target.value)} />
                <div className='flex flex-row mt-3 gap-4'>
                    <div>
                        <span className=' font-heebo text-md font-light'>From</span>
                        <Dropdown list={locations} setSelected={(item) => setSelectedFrom(item)} selectedItem={selectedFromSaved} dropdownWidth={"250"} />
                    </div>
                    <div>
                        <span className=' font-heebo text-md font-light'>to</span>
                        <Dropdown list={locations} setSelected={(item) => setSelectedTo(item)} selectedItem={selectedToSaved} dropdownWidth={"250"} />
                    </div>
                </div>
                <div className='w-full flex justify-center mt-14'>
                    {(selectedName !== '' && selectedFromSaved !== selectedToSaved) ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                            <span className='text-white font-heebo'>Confirm Escort</span>
                            <IconTint src={escortIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Confirm Escort</span>
                            <IconTint src={escortIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default EscortPopup;
