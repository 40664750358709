import React, { useRef, useState } from 'react'
import IconTint from 'react-icon-tint';
import downIcon from '../assets/icons/ic_arrowdown.svg'
import upIcon from '../assets/icons/ic_arrowup.svg'

const Dropdown = ({list: items, setSelected: setSelectedItem, selectedItem: selected, dropdownWidth}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => setIsOpen(!isOpen);
    const myComponentRef = useRef(null);
    
    document.addEventListener("mousedown", (e) => {
        if (myComponentRef.current && !myComponentRef.current.contains(e.target)) {
            setIsOpen(false);
        }
    });

    const onSelected = (item) => {
        setSelectedItem(item)
        setIsOpen(false);
    }

    return (
        <div ref={myComponentRef} className="relative w-fit transition-all delay-200 select-none mt-1">
            <div onClick={toggleDropdown}  style={{ minWidth: `${dropdownWidth}px` }} className={`cursor-pointer w-fit px-3 py-1 bg-[#3C5BD2] bg-opacity-10  ${isOpen ? 'rounded-bl-none rounded-rb-none rounded-tr-md rounded-tl-md' : 'rounded-md hover:bg-opacity-20'}  focus:outline-none flex flex-row items-center justify-between`}>
                <span className='font-heebo font-light'>{selected}</span>
                {!isOpen ? <IconTint src={downIcon} className='h-3' color='#3C5BD2' /> : <IconTint src={upIcon} className='h-3 ml-3 -mt-1' color='#3C5BD2' />}
            </div>

            {isOpen && (
                <div className="absolute left-92 z-10 w-full py-2 bg-[#e1e6fd] rounded-b-md shadow-xl ">
                    {items.map((item, index) => (
                        <span
                            key={index}
                            className="block px-4 py-1.5 text-md font-light cursor-pointer font-heebo hover:bg-[#b9c7ff] hover:bg-opacity-20"
                            onClick={() => onSelected(item)}
                        >{item}</span>
                    ))
                    }
                </div>
            )}
        </div>
    );
};


export default Dropdown