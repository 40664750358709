import React, { useEffect, useState } from 'react';
import IconTint from 'react-icon-tint';
import qrIcon from '../../assets/icons/ic_qrcode.svg'
import trashIcon from '../../assets/icons/ic_trash.svg'
import { useAtom } from 'jotai';
import QRCode from 'qrcode';
import { qrCodeTitle, qrCodeURL, webUrl } from '../store/DataStore';

const QRPopup = ({ isOpen, children, onClose, onSend }) => {
    const [show, setShow] = useState(isOpen);
    const [qrURL, setqrURL] = useAtom(qrCodeURL);
    const [qrTitle, setQrTitle] = useAtom(qrCodeTitle);
    const [isShowQR, setIsShowQR] = useState(false);
    const [QRinput, setQRinput] = useState('');
    const [webURLsaved, setWebURLsaved] = useAtom(webUrl)

    useEffect(() => {
        if (isOpen) {
            setShow(true);
            console.log(webURLsaved);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const handleClose = () => {
        setQRinput('');
        setQrTitle('');
        setIsShowQR(false)
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleSend = () => {
        onSend();
        setQRinput('');
        setQrTitle('');
        setIsShowQR(false)
        setShow(false);
        setTimeout(onClose, 200);
    }

    function generateQRCode(url, options = {}) {
        QRCode.toDataURL(url, options, function (err, url) {
            if (err) {
                setIsShowQR(false)
                console.log(err);
            } else {
                setqrURL(url)
                setIsShowQR(true)
            }
        });
    }

    const setNewUrl = (e) => {
        setQRinput(e.target.value);
        
        const urlPattern = new RegExp(
            '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', // fragment locator
            'i'
        );
        if (urlPattern.test(e.target.value)) {
            generateQRCode(e.target.value, { color: { dark: '#000', light: '#FFF' } });
            setWebURLsaved(e.target.value);
        } else {
            setIsShowQR(false)
            console.error('Invalid URL format');
        }
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-[full] transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-[600px] shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
                <div className='mt-7 flex flex-col items-center'>
                    <div className='flex flex-col'>
                        <span className=' font-heebo font-light'>This Title will appear on the robot screen</span>
                        <input className='bg-[#3C5BD2] bg-opacity-10 px-2 py-4 h-7 mt-1 rounded-md w-[350px] outline-none font-heebo font-light' maxLength={50} placeholder='Enter title here...' value={qrTitle} onChange={(e) => setQrTitle(e.target.value)} />
                    </div>

                    <div className='flex flex-col mt-4 ml-7'>
                        <span className='font-heebo font-light'>Insert QR URL</span>
                        <div className='flex flex-row'>
                            <div className='bg-[#3C5BD2] bg-opacity-10 px-2 py-4 h-7 mt-1 rounded-md w-[350px] outline-none font-heebo font-light flex flex-row items-center'>
                                <IconTint src={qrIcon} className='h-5' color='#152FF5' />
                                <input className='outline-none font-heebo font-light bg-transparent w-full ml-2' placeholder='Enter URL here...' value={QRinput} onChange={setNewUrl} />
                            </div>
                            <img src={trashIcon} alt='remove' className='ml-2 mt-1 cursor-pointer' onClick={() => {setQRinput(''); setqrURL(''); setIsShowQR(false)} }/>
                        </div>
                    </div>
                    <img src={qrURL} alt='qrcode' className={`w-28 mt-6 ${!isShowQR && 'hidden'}`} />
                </div>

                <div className='w-full flex justify-center mt-10'>
                    {(qrTitle !== '' && isShowQR) ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                            <span className='text-white font-heebo'>Save QR</span>
                            <IconTint src={qrIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Save QR</span>
                            <IconTint src={qrIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default QRPopup;
