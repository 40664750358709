import { initializeApp } from "firebase/app"; 
import { getAuth } from  "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCGr3ghAqf34XwTeQCICmlWXpa0TKoOmH0",
  authDomain: "toyota-cd2b6.firebaseapp.com",
  databaseURL: "https://toyota-cd2b6.firebaseio.com",
  projectId: "toyota-cd2b6",
  storageBucket: "toyota-cd2b6.appspot.com",
  messagingSenderId: "509395250467",
  appId: "1:509395250467:web:dd599f235003a4cde17096"
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
export {auth};
export {db};
export {storage};

