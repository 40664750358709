import React, { useEffect, useState } from 'react';
import locationIcon from '../../assets/icons/ic_location.svg'
import IconTint from 'react-icon-tint';
import Dropdown from '../Dropdown';
import { savedSelectedLoc } from '../store/DataStore';
import { useSetAtom } from 'jotai';

const ComeHerePopup = ({ isOpen, children, onClose, onSend, locations, currentLocation }) => {
    const [show, setShow] = useState(isOpen);
    const [selectedToSaved, setSelectedToSaved] = useState('');
    const setSelectedGoto = useSetAtom(savedSelectedLoc)
    const [isDropdownTouched, setIsDropdownTouched] = useState(false)

    useEffect(() => {
        if (isOpen) {
            setShow(true);
            if(!isDropdownTouched){
                setSelectedToSaved(locations[0])
            }
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
        setSelectedToSaved(locations[0])
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
        setSelectedToSaved(locations[0]);
    }

    const setSelectedTo = (item) => {
        setIsDropdownTouched(true);
        setSelectedToSaved(item);
        setSelectedGoto(item);
        console.log("selected to " + item)
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white ml"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="-mt-4 flex flex-col items-center">
                    {children}
                </div>
                <div className='flex flex-row mt-3 gap-5'>
                    <div className='flex flex-col'>
                        <span className='font-heebo text-md font-light'>Current Location</span>
                        <span className=' font-heebo bg-[#3C5BD2] bg-opacity-5 px-3 py-1 rounded-md mt-1 w-[250px] text-[#476bfb]'>{currentLocation !== '' ? currentLocation : 'Movement needed'}</span>
                    </div>
                    <div>
                        <span className='font-heebo text-md font-light'>Destination</span>
                        <Dropdown list={locations} setSelected={(item) => setSelectedTo(item)} selectedItem={selectedToSaved} dropdownWidth={"250"} />
                    </div>
                </div>
                <div className='w-full flex justify-center mt-14'>
                    {(true) ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                            <span className='text-white font-heebo'>Confirm</span>
                            <IconTint src={locationIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Confirm</span>
                            <IconTint src={locationIcon} className='h-5 ml-2' color='#FFFFFF' />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ComeHerePopup;
