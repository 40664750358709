// Filename - ProgressBar.js
import React from 'react';

const ProgressBar = ({ bgcolor, progress, height }) => {
    // Inline style for dynamic width based on progress
    const widthStyle = { width: `${progress}%` };

    return (
        <div className={`2xl:w-32 lg:w-24 bg-gray-200 rounded-full m-2`} style={{ height: height }}>
            <div 
                className="h-full rounded-full"
                style={{ ...widthStyle, backgroundColor: bgcolor }}
            >
            </div>
        </div>
    );
};

export default ProgressBar;
