import React, { useEffect, useState } from 'react';

const RobotSerialNumberPopup = ({ isOpen, onClose, onSend }) => {
    const [show, setShow] = useState(isOpen);
    const [serialNumber, setSerialNumber] = useState('');

    useEffect(() => {
        if (isOpen) {
            setShow(true);
            setSerialNumber('');
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
    }, [isOpen, onClose]);

    const handleClose = () => {
        setSerialNumber('');
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleSend = () => {
        onSend(serialNumber);
        setSerialNumber('');
        setShow(false);
        setTimeout(onClose, 200);
    };

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'} z-20`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative top-20 mx-auto p-4 border w-fit shadow-lg rounded-md bg-white"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-end">
                    <button onClick={handleClose} className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-0.5 text-sm ml-auto inline-flex items-center">
                        <svg className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col items-center">
                    <div className='flex flex-col mt-3 gap-1'>
                        <span className='font-heebo font-light'>Robot Serial Number</span>
                        <input value={serialNumber} onChange={(e) => setSerialNumber(e.target.value)} className='font-heebo font-light w-56 bg-[#e1e6fd] p-1 px-2 rounded-md items-center outline-none' placeholder='Enter serial number' />
                    </div>
                </div>
                <div className='w-full flex justify-center mt-14'>
                    {serialNumber !== '' ?
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center cursor-pointer' onClick={handleSend}>
                            <span className='text-white font-heebo'>Submit</span>
                        </div>
                        :
                        <div className='flex w-fit bg-[#152FF5] py-1.5 px-5 rounded-3xl items-center opacity-50'>
                            <span className='text-white font-heebo'>Submit</span>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default RobotSerialNumberPopup;