import React from 'react';

const RemoveUserPopup = ({ isOpen, userName, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow-lg">
                <p>Are you sure you want to remove the user {userName}?</p>
                <div className="mt-4 flex justify-end">
                    <button onClick={onCancel} className="mr-2 px-4 py-2 bg-gray-300 rounded">Cancel</button>
                    <button onClick={onConfirm} className="px-4 py-2 bg-red-500 text-white rounded">Yes</button>
                </div>
            </div>
        </div>
    );
};

export default RemoveUserPopup;